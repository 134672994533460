//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
    progresscom: () => import("./progressCom"),
  },
  props: {
    //是否显示截图
    IsRealTimeScreenshot: {
      type: Boolean,
      default: true,
    },
    selActiveTime: {
      type: String,
      default: null,
    },
    datestart: {
      type: String,
      default: null,
    },
    dateend: {
      type: String,
      default: null,
    },
    teamValue: {
      type: Number,
      default: null,
    },
    selUser: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tableData: null,
      selRow: null, //选择的窗口
      stime: null,
      etime: null,
    };
  },
  methods: {
    /**
     * 行为分析标签颜色
     * 积极：绿  消极：红  无：白色
     */
    getTagType(val) {
      if (val == "积极") {
        return "success";
      } else if (val == "消极") {
        return "danger";
      } else {
        return "info";
      }
    },
    handleWindowKey(row) {
      this.selRow = {
        name: row.FocusFormName,
      };
      this.$nextTick(() => {
        this.$modal.show("allbehaviorpic");
      });
    },
    /**
     * 查看行为
     */
    handleBehavior(row) {
      this.$emit("handleBehavior", row, this.tableData);
    },
    /**
     * 打开弹窗回调
     */
    opened() {
      this.$nextTick(() => {
        this.stime = this.selActiveTime
          ? this.selActiveTime.timeFormat("yyyy-MM-dd 00:00:01")
          : this.datestart;
        this.etime = this.selActiveTime
          ? this.selActiveTime.timeFormat("yyyy-MM-dd 23:59:59")
          : this.dateend;
        this.tableData = this.selUser;
        //树形表格添加id
        if (
          this.tableData &&
          this.tableData.Behaviors &&
          this.tableData.Behaviors.length
        ) {
          this.tableData.Behaviors.forEach((e, index) => {
            e.haschil = true;
            e.rid = index + 1;
            if (e.FormNames && e.FormNames.length) {
              e.FormNames.forEach((s, ind) => {
                s.rid = `${index + 1}-${ind}`;
                s.DateSumNum = s.DateNum;
                s.ClickSumNum = s.ClickNum;
                s.KeySumNum = s.KeyNum;
              });
            }
          });
        }
      });
    },

    attenceHeaderStyle() {
      return {
        color: "#909399",
      };
    },
  },
};
